import { useState } from "react";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../../templates/PageTemplate/PageTemplate";
import GenericTable from "../../../molecules/GenericTable/GenericTable";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useAppSelector } from "../../../../rtk/_config/hooks";
import { RootState } from "../../../../types/rtk/api-config/types";
import { useGetOTACampainsQuery } from "../../../../rtk/software/ota-campains/ota-campains";
import { formatDate } from "../../../../helpers/formatDate";
import DeployRateComponent from "../../../atoms/DeployRateComponent/DeployRateComponent";
import LinearProgressWithLabel from "../../../atoms/LinearProgressWithLabel/LinearProgressWithLabel";
import StyledChip from "../../../atoms/StyledChip/StyledChip";
import { hasQueryFilters } from "../../../../helpers/hasQueryFilter";
import ConditionalWrapper from "../../../atoms/ConditionalWrapper/ConditionalWrapper";

interface Props {
  template?: boolean;
}

const OTACampains = ({ template = true }: Props) => {
  const navigate = useNavigate();
  const types = useAppSelector((state: RootState) => state.openApi.types);
  const [query, setQuery] = useState("");
  const colDef =
    types?.components.schemas.OtaCampainListSummaryRead.properties ?? {};
  const {
    data: campain,
    isLoading,
    isFetching,
  } = useGetOTACampainsQuery(query, {
    skip: query === "",
  });

  const colToOverride: GridColDef[] = [
    {
      field: "firmware_to_label",
      headerName: "LABEL TO",
      renderCell: (params: GridRenderCellParams) => (
        <Box
          onClick={() =>
            navigate(`${AppRoutes.MANAGE_OTA_CAMPAIN}/${params.id.toString()}`)
          }
          className="clickableBox"
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "firmware_to_name",
      headerName: "Firmware TO",
      renderCell: (params: GridRenderCellParams) => (
        <Box
          onClick={() =>
            navigate(
              `${
                AppRoutes.MANAGE_FIRMWARE
              }/${params.row.firmware_to_id.toString()}`
            )
          }
          className="clickableBox"
        >
          {params.row.firmware_to_name}
        </Box>
      ),
    },
    {
      field: "total",
      headerName: "TOTAL TARGET",
    },
    {
      field: "created_at",
      headerName: "DATE",
      valueGetter: (created_at: string) => formatDate(created_at ?? ""),
    },
    {
      field: "is_closed",
      headerName: "STATUS",
      renderCell: (params: GridRenderCellParams) => (
        <StyledChip
          label={
            params.value === true
              ? "DONE".toUpperCase()
              : "IN PROGRESS".toUpperCase()
          }
          color={params.value === true ? "success" : "warning"}
        />
      ),
    },
    {
      field: "target_percent",
      headerName: "DEPLOY RATE",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <DeployRateComponent
            totalTrackers={params.row.total}
            migratedTrackers={params.row.tracker_selected}
            deployRate={params.row.target_percent}
          />
        );
      },
    },
    {
      field: "current_percent",
      headerName: "MIGRATED RATE",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <LinearProgressWithLabel
            value={params.row.current_percent}
            tracker={params.row.trackers_already_updated}
            total={params.row.total}
          />
        );
      },
    },
  ];

  const clearFilter = () => {
    setQuery("");
  };

  return (
    <ConditionalWrapper
      condition={template}
      wrapper={(children) => (
        <PageTemplate
          title="OTA Campaign"
          button="New campain"
          link={AppRoutes.CREATE_OTA_CAMPAIN}
          clearFilterButton={
            hasQueryFilters(query) ? "Remove filters" : undefined
          }
          onClickClearFilterButton={clearFilter}
        >
          {children}
        </PageTemplate>
      )}
    >
      <Box className="OTACampains">
        {types && (
          <GenericTable
            rawData={campain}
            isLoading={isLoading}
            isFetching={isFetching}
            colDef={colDef}
            colToOverride={colToOverride}
            setQuery={setQuery}
            query={query}
          />
        )}
      </Box>
    </ConditionalWrapper>
  );
};

export default OTACampains;
