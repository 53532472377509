import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import PageTemplate from "../../templates/PageTemplate/PageTemplate";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import DataDisplay from "../../molecules/DataDisplay/DataDisplay";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { formatDate } from "../../../helpers/formatDate";
import { AppRoutes } from "../../../routes/AppRoutes";
import {
  useGetOrderB2BByIdQuery,
  useDeleteOrderB2BMutation,
} from "../../../rtk/orderB2B/orderB2B";

const ManageOrderB2B = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { data: orderB2B, isLoading: isLoadingOrder } = useGetOrderB2BByIdQuery(
    id ?? "",
    {
      skip: !id,
    }
  );
  const [deleteOrderB2B] = useDeleteOrderB2BMutation();

  const orderB2BDataToDisplay = [
    { title: "Id", selector: "id" },
    { title: "Order number", selector: "order_number" },
    {
      title: "Created by",
      selector: "bo_users.username",
    },
    { title: "Created at", selector: "created_at", callBack: formatDate },
    {
      title: "Retailer",
      selector: "retailer.name",
    },
    {
      title: "Retailer id",
      selector: "retailer.id",
    },
  ];

  const handleDeleteOrderB2B = async () => {
    setIsLoading(true);
    try {
      await deleteOrderB2B(orderB2B?.id?.toString() ?? "");
      navigate(AppRoutes.ORDERB2B);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Manage Order B2B" cross>
      <Box className="ManageOrderB2B">
        <StyledPaper>
          {!isLoadingOrder ? (
            <>
              <Box className="dataHeader">
                <Title variant="h3" children={orderB2B?.order_number} />
                <Text children={`orderB2B id: ${orderB2B?.id ?? ""}`} />
              </Box>

              <Box className="data">
                <DataDisplay
                  datas={orderB2B}
                  datasInformations={orderB2BDataToDisplay}
                  orientation={"horizontal"}
                />
              </Box>

              <Box className="buttons">
                {!isLoading ? (
                  <>
                    <StyledButton
                      children="Delete"
                      className="cancel"
                      onClick={handleDeleteOrderB2B}
                    />
                  </>
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            </>
          ) : (
            <SpinnerLoader />
          )}
        </StyledPaper>
        {/*<StyledPaper>
          <Box className="dataHeader">
            <Title variant="h3" children={"Tracker list"} />
          </Box>
            TODO
          <Box className="data"></Box>
        </StyledPaper> */}
      </Box>
    </PageTemplate>
  );
};

export default ManageOrderB2B;
